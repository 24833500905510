const initialState = {
  isVHModalInsertOpen: false,
  isVHModalAddNewHVOpen: false,
  isVHModalAddHVOpen: false,
  isVHModalUpdateOpen: false,
  isVHModalUpdateHVOpen: false,
  VHEntriesData: [],
  VHEntryData: null,
  filterVHEntriesDataMain: [],
  currentTab: 0,
  isLoadingVH: true,
};

const VHReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_VH_MODAL_INSERT":
      return {
        ...state,
        isVHModalInsertOpen: !state.isVHModalInsertOpen,
      };
    case "TOGGLE_VH_MODAL_ADD_NEW":
      return {
        ...state,
        isVHModalAddNewHVOpen: !state.isVHModalAddNewHVOpen,
      };
    case "TOGGLE_VH_MODAL_ADD":
      return {
        ...state,
        isVHModalAddHVOpen: !state.isVHModalAddHVOpen,
      };
    case "TOGGLE_VH_MODAL_UPDATE":
      return {
        ...state,
        isVHModalUpdateOpen: !state.isVHModalUpdateOpen,
        VHEntryData: action.payload,
      };
    case "TOGGLE_VH_MODAL_UPDATE_HV":
      return {
        ...state,
        isVHModalUpdateHVOpen: !state.isVHModalUpdateHVOpen,
      };
    case "FETCH_VH":
      return {
        ...state,
        VHEntriesData: action.payload,
        filterVHEntriesDataMain: action.payload,
      };
    case "SWITCH_TAB_VH":
      return {
        ...state,
        currentTab: action.payload,
      };
    case "SET_LOADING_VH":
      return {
        ...state,
        isLoadingVH: action.payload,
      };
    default:
      return state;
  }
};

export default VHReducer;
