import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Route,
  BrowserRouter,
  HashRouter,
  Switch,
  Redirect,
} from "react-router-dom";

import LoginGuard from "../components/guard";
import { actLoginUser, logout } from "../store/actions/user";
import setAuthToken from "../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import { Store } from "tough-cookie";
import { declareTypeAlias } from "@babel/types";
import { ROUTE } from "../store/constants/config";
import FaceCheckInStudent from "../views/FaceRecpush/FaceCheckInStudent";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("../containers/DefaultLayout"));

//Login
const Login = React.lazy(() => import("../views/Login"));
const Register = React.lazy(() => import("../views/Register"));
const FaceRecpush = React.lazy(() =>
  import("../views/FaceRecpush/FaceRegisterRecpush")
);
const FaceRecpushStudent = React.lazy(() =>
  import("../views/FaceRecpush/FaceRegisterRecpushStudent")
);
const FaceCheckIn = React.lazy(() =>
  import("../views/FaceRecpush/FaceCheckIn")
);
const FaceCheckOut = React.lazy(() =>
  import("../views/FaceRecpush/FaceCheckOut")
);
const FaceVisitor = React.lazy(() =>
  import("../views/FaceRecpush/VisitorAppointment")
);
const Home = React.lazy(() => import("../views/FaceRecpush/Home"));
const HomePage = React.lazy(() => import("../views/FaceRecpush/HomePage"));
const His = React.lazy(() => import("../views/FaceRecpush/HisTimesheet"));
const HisDemo = React.lazy(() => import("../views/FaceRecpush/DemoHis"));
const FeedbackForm = React.lazy(() =>
  import("../views/Department/FeedbackForm")
);
const ExerciseUpload = React.lazy(() =>
  import("../views/Department/ExerciseUpload")
);
const BODViewPageTAQT = React.lazy(() =>
  import("../views/Enroll/TAQT/BODViewPageTAQT")
);
const BODViewPageVH = React.lazy(() =>
  import("../views/Enroll/VH/BODViewPageVH")
);
const StudentInfo = React.lazy(() =>
  import("../views/Department/StudentInfo")
);


class Layout extends Component {
  render() {
    // console.log(localStorage.jwtToken)
    if (localStorage.jwtToken) {
      // console.log("kkk")
      setAuthToken(localStorage.jwtToken);
      const decoded = jwtDecode(localStorage.jwtToken);
      // this.props.actLoginUser(decoded);
      const currentTime = new Date().getTime() / 1000;
      // console.log(decoded)
      // console.log(decoded.iat + 3600, currentTime)
      // if(decoded.iat + 3600 < currentTime){
      //   this.props.onCheckToken()
      //   window.location.href = "/"
      // }
    }
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />

            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />

            <Route
              exact
              path="/FaceRecpush"
              name="Nhận diện khuôn mặt"
              render={(props) => <FaceRecpush {...props} />}
            />

            <Route
              exact
              path="/FaceRecpushStudent"
              name="Nhận diện khuôn mặt"
              render={(props) => <FaceRecpushStudent {...props} />}
            />

            <Route
              exact
              path="/FaceCheckIn"
              name="Chấm công vào"
              render={(props) => <FaceCheckIn {...props} />}
            />

            <Route
              exact
              path="/FaceCheckInStudent"
              name="Chấm công vào"
              render={(props) => <FaceCheckInStudent {...props} />}
            />

            <Route
              exact
              path="/FaceCheckOut"
              name="Chấm công ra"
              render={(props) => <FaceCheckOut {...props} />}
            />

            <Route
              exact
              path="/VisitorAppointment"
              name="Đăng ký lịch hẹn"
              render={(props) => <FaceVisitor {...props} />}
            />

            <Route
              exact
              path="/VisitorAppointment/:id"
              name="Đăng ký lịch hẹn"
              render={(props) => <FaceVisitor {...props} />}
            />

            <Route
              exact
              path="/Home"
              name="Trang chủ"
              render={(props) => <Home {...props} />}
            />

            <Route
              exact
              path="/HomePage"
              name="Trang chủ"
              render={(props) => <HomePage {...props} />}
            />

            <Route
              exact
              path="/HisTimesheet"
              name="Lịch sử chấm công"
              render={(props) => <His {...props} />}
            />

            <Route
              exact
              path="/HisDemo"
              name="Lịch sử chấm công"
              render={(props) => <HisDemo {...props} />}
            />

            <Route
              exact
              path="/feedback/:id"
              name="Phản hồi và góp ý"
              render={() => <FeedbackForm />}
            />

            <Route
              exact
              path="/exercise/:id"
              name="Nộp bài tập"
              render={() => <ExerciseUpload />}
            />

            <Route
              exact
              path="/student"
              name="Thông tin học viên"
              render={() => <StudentInfo />}
            />

            <LoginGuard path={"/auth"} component={DefaultLayout} />

            <Route
              exact
              path="/bod/taqt"
              name="Trang xem TAQT"
              render={() => <BODViewPageTAQT />}
            />

            <Route
              exact
              path="/bod/vh"
              name="Trang xem VH"
              render={() => <BODViewPageVH />}
            />

            <Route
              exact
              path="/"
              name="Trang chủ"
              render={(props) => <Home {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
  componentDidMount() {
    const user = localStorage.getItem("userLogin");

    if (user) {
      this.props.onLoadUserProfile(JSON.parse(user));
    }

    const intervalID = setInterval(() => {
      this.setState(
        {
          intervalID,
          time: new Date(Date.now()).toLocaleTimeString(),
          date: new Date(Date.now()).toLocaleDateString(),
        },
        () => {}
      );
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.time, this.date);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadUserProfile: (user) => {
      // console.log(user)
      const { token } = user;
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(actLoginUser(decoded));
      //   dispatch(actLoginUser(user));
    },
    onCheckToken: () => {
      dispatch(logout());
    },
  };
};

export default connect(null, mapDispatchToProps)(Layout);

// export default Layout;
