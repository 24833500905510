import { combineReducers } from "redux";
import userReducer from "./user";
import errorsReducer from "./errors";
import studentReducer from "./student";
import mapReducer from "./map";
import TAQTReducer from "./taqt";
import VHReducer from "./vh";

const rootReducer = combineReducers({
  user: userReducer,
  errors: errorsReducer,
  student: studentReducer,
  map: mapReducer,
  taqt: TAQTReducer,
  vh: VHReducer,
});

export default rootReducer;
