const initialState = {
  isTAQTModalInsertOpen: false,
  isTAQTModalAddNewHVOpen: false,
  isTAQTModalAddHVOpen: false,
  isTAQTModalUpdateOpen: false,
  isTAQTModalUpdateHVOpen: false,
  TAQTEntriesData: [],
  TAQTEntryData: null,
  currentTab: 0,
  isLoadingTAQT: true,
};

const TAQTReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_TAQT_MODAL_INSERT":
      return {
        ...state,
        isTAQTModalInsertOpen: !state.isTAQTModalInsertOpen,
      };
    case "TOGGLE_TAQT_MODAL_ADD_NEW":
      return {
        ...state,
        isTAQTModalAddNewHVOpen: !state.isTAQTModalAddNewHVOpen,
      };
    case "TOGGLE_TAQT_MODAL_ADD":
      return {
        ...state,
        isTAQTModalAddHVOpen: !state.isTAQTModalAddHVOpen,
      };
    case "TOGGLE_TAQT_MODAL_UPDATE":
      return {
        ...state,
        isTAQTModalUpdateOpen: !state.isTAQTModalUpdateOpen,
        TAQTEntryData: action.payload,
      };
    case "TOGGLE_TAQT_MODAL_UPDATE_HV":
      return {
        ...state,
        isTAQTModalUpdateHVOpen: !state.isTAQTModalUpdateHVOpen,
      };
    case "FETCH_TAQT":
      return {
        ...state,
        TAQTEntriesData: action.payload,
      };
    case "SWITCH_TAB":
      return {
        ...state,
        currentTab: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoadingTAQT: action.payload,
      };
    default:
      return state;
  }
};

export default TAQTReducer;
